const POSTCODES_BN = {
	// B: 'Brunei Muara',
	// K: 'Belait',
	// T: 'Tutong',
	// P: 'Temburong',
	// BS: 'Bandar Seri Begawan',
	// BA: 'Mukim Kianggeh',
	// BB: 'Mukim Berakas A',
	// BC: 'Mukim Berakas B',
	// BD: 'Mukim Kota Batu',
	// BE: 'Mukim Gadong',
	// BF: 'Mukim Kilanas',
	// BG: 'Mukim Sengkurong',
	// BH: 'Mukim Pengkalan Batu',
	// BJ: 'Mukim Lumapas',
	// BK: 'Mukim Sungai Kebun',
	// BL: 'Mukim Tamoi',
	// BM: 'Mukim Burong Pinggai Ayer',
	// BN: 'Mukim Sungai Kedayan',
	// BP: 'Mukim Peramu',
	// BR: 'Mukim Saba',
	// BT: 'Mukim Serasa',
	// BU: 'Mukim Mentiri',
	// KA: 'Mukim Kuala Belait',
	// KB: 'Mukim Seria',
	// KC: 'Mukim Liang',
	// KD: 'Mukim Kuala Balai',
	// KE: 'Mukim Labi',
	// KF: 'Mukim Bukit Sawat',
	// KG: 'Mukim Sukang',
	// KH: 'Mukim Melilas',
	// TA: 'Mukim Pekan Tutong',
	// TB: 'Mukim Keriam',
	// TC: 'Mukim Telisai',
	// TD: 'Mukim Tanjong Maya',
	// TE: 'Mukim Kiudang',
	// TF: 'Mukim Ukong',
	// TG: 'Mukim Lamunin',
	// TH: 'Mukim Rambai',
	// PA: 'Mukim Bangar',
	// PB: 'Mukim Labu',
	// PC: 'Mukim Batu Apoi',
	// PD: 'Mukim Amo',
	// PE: 'Mukim Bokok',
	BS8111: 'Kianggeh',
	BS8211: 'Berangan',
	BS8311: 'Tasek Lama',
	BS8411: 'Padang',
	BS8511: 'Sumbiling',
	BS8611: 'Kawasan Pusat Sejarah',
	BS8711: 'Kawasan Yayasan Sultan Haji Hassanal Bolkiah',
	BS8811: 'Kawasan Perdagangan Bumi Putera',
	BA1111: 'Kawasan Mabohai',
	BA1311: 'Kawasan Tong Kadeh',
	BA1511: 'Kumbang Pasang',
	BA1712: 'Seri Komplek',
	BA1912: 'Parit (Serigan)',
	BA2112: 'Tumasek',
	BA2312: 'Telanai',
	BB1114: 'Kampong Lambak Kiri (Kurnia Rakyat Jati)',
	BB1314: 'Kampong Lambak Kiri A',
	BB1514: 'Kampong Terunjing Baru',
	BB1714: 'Kampong Lambak B',
	BB1914: 'Kawasan Bee Seng Garden',
	BB2114: 'Kampong Terunjing Lama',
	BB2313: 'Kampong Serusop',
	BB2513: 'Kawasan Lapangan Terbang Antarabangsa',
	BB2713: 'Kampong Jaya Setia',
	BB3113: 'Kampong Jaya Bakti',
	BB3313: 'Kampong Burong Pinggai Berakas',
	BB3577: 'Kawasan Lapangan Terbang Lama',
	BB3713: 'Kampong Anggerek Desa',
	BB3913: 'Kawasan Jalan Menteri Besar',
	BB4113: 'Kampong Orang Kaya Besar Imas',
	BB4313: 'Kampong Pulaie',
	BB4513: 'Kampong Pancha Delima',
	// BB2713: 'Kampong Delima Satu', // Duplicate Postcode. Cannot find this kampong on post.gov.bn
	BB5113: 'Kampong Pengiran Siraja Muda',
	BB5313: 'Kampong Delima Dua',
	BC1115: 'Taman Salambigar',
	BC1515: 'Kampong Salambigar',
	BC1715: 'Kampong Sungai Orok',
	BC1915: 'Taman Suraya',
	BC2115: 'Kampong Sungai Hanching',
	BC2315: 'Perumahan Negara Kawasan 1 (Lambak Kanan)',
	BC2515: 'Perumahan Negara Kawasan 2 (Lambak Kanan)',
	BC2715: 'Perumahan Negara Kawasan 3 (Lambak Kanan)',
	BC2915: 'Perumahan Negara Kawasan 4 (Lambak Kanan)',
	BC3115: 'Perumahan Negara Kawasan 5 (Lambak Kanan)',
	BC3315: 'Kampong Sungai Tilong',
	BC3515: 'Kampong Manggis Dua',
	BC3615: 'Kampong Manggis Satu',
	BC3715: 'Kampong Madang',
	BC3915: 'Sungai Akar (Jalan Subok)',
	BC4115: 'Kampong Sungai Akar (Jalan Kebangsaan Lama)',
	BD1117: 'Kampong Sungai Lampai',
	BD1317: 'Kampong Pintu Malim',
	BD1517: 'Kampong Kota Batu',
	BD1717: 'Kampong Datu Gandi',
	BD1917: 'Kampong Sungai Matan',
	BD2117: 'Kampong Serdang/Sungai Belukut',
	BD2317: 'Kampong Pelambayan',
	BD2517: 'Kampong Sungai Besar',
	BD2717: 'Kampong Subok',
	BD2917: 'Kampong Belimbing',
	BD3117: 'Perpindahan Tanjong Semasta',
	BD3317: 'Kampong Sungai Bunga',
	BD3517: 'Kampong Pudak',
	BD3717: 'Kampong Berambang',
	BD3917: 'Kampong Buang Kepayang',
	BD4117: 'Kampong Riong',
	BD4317: 'Kampong Menunggol',
	BD4517: 'Kampong Pulau Baru-Baru',
	BE1118: 'Kampong Beribi',
	BE1318: 'Kampong Kiarong',
	BE1518: 'Kampong Kiulap',
	BE1718: 'Kampong Mata-Mata',
	BE1918: 'Perumahan Mata-Mata',
	BE2119: 'Kampong Tungku',
	BE2319: 'Kampong Katok',
	BE2519: 'Perumahan Negara Tungku',
	BE2719: 'Kampong Gadong',
	BE2919: 'Kampong Gadong Estate',
	BE3119: 'Kampong Rimba',
	BE3319: 'Perumahan Negara Rimba',
	BE3519: 'Kampong Batu Bersurat',
	BE3719: 'Kampong Pengkalan Gadong',
	BE3919: 'Kampong Menglait I',
	BE4119: 'Kampong Menglait II',
	BF1112: 'Kampong Medewa',
	BF1312: 'Kampong Bunut Perpindahan',
	BF1512: 'Kampong Tasek Meradun',
	BF1720: 'Kampong Burong Lepas',
	BF1920: 'Kampong Bengkurong',
	BF2120: 'Kampong Sinarubai',
	BF2320: 'Kampong Bebatik',
	BF2520: 'Kampong Kilanas',
	BF2720: 'Kampong Jangsak',
	BF2920: 'Kampong Tanjong Bunut',
	BG1121: 'Kampong Sengkurong A',
	BG1321: 'Kampong Sengkurong B',
	BG1521: 'Kampong Tagap',
	BG1721: 'Kampong Selayun',
	BG1921: 'Kampong Sungai Tampoi',
	BG2121: 'Kampong Mulaut',
	BG2321: 'Kampong Tanjong Nangka',
	BG2521: 'Kampong Kulapis',
	BG2721: 'Kampong Katimahar',
	BG2921: 'Kampong Lugu',
	BG3122: 'Kampong Jerudong',
	BG3322: 'Kampong Bukit Bunga',
	BH1123: 'Kampong Pengkalan Batu',
	BH1323: 'Kampong Batu Ampar',
	BH1523: 'Kampong Batang Perhentian',
	BH1723: 'Kampong Bukit Belimbing',
	BH1923: 'Kampong Kuala Lurah',
	BH2123: 'Kampong Junjongan',
	BH2323: 'Kampong Limau Manis',
	BH2523: 'Kampong Wasan',
	BH2723: 'Kampong Masin',
	BH2923: 'Kampong Batong',
	BH3123: 'Kampong Panchor Murai',
	BH3323: 'Kampong Bebuloh',
	BJ1124: 'Kampong Kupang',
	BJ1324: 'Kampong Putat',
	BJ1524: 'Kampong Pengkalan Batang',
	BJ1724: 'Kampong Kasat',
	BJ1924: 'Kampong Buang Sakar',
	BJ2124: 'Kampong Tarap Bau',
	BJ2324: 'Kampong Bukit Merikan',
	BJ2524: 'Kampong Lupak Luas',
	BJ2724: 'Kampong Sungai Asam',
	BJ2924: 'Kampong Buang Tengkorok',
	BJ3124: 'Kampong Sengkirap',
	BJ3324: 'Kampong Lumapas A',
	BJ3524: 'Kampong Lumapas B',
	BJ3724: 'Kampong Pancur',
	BJ3924: 'Kampong Kelugus',
	BK1125: 'Kampong Bolkiah A',
	BK1325: 'Kampong Bolkiah B',
	BK1525: 'Kampong Setia A',
	BK1725: 'Kampong Setia B',
	BK1925: 'Kampong Sungai Siamas',
	BK2125: 'Kampong Ujong Kelinik',
	BK2325: 'Kampong Sungai Kebun',
	BL1112: 'Kampong Ujong Bukit',
	BL1312: 'Kampong Limbongan',
	BL1512: 'Kampong Bendahara Lama',
	BL1712: 'Kampong Pengiran Kerma Indera',
	BL1912: 'Kampong Pengiran Tajudin Hitam',
	BL2112: 'Kampong Tamoi Tengah',
	BM1126: 'Kampong Burong Pinggai Ayer',
	BM1326: 'Kampong Lurong Dalam',
	BM1526: 'Kampong Pandai Besi A',
	BM1726: 'Kampong Pandai Besi B',
	BM1926: 'Kampong Sungai Pandan A',
	BM2126: 'Kampong Sungai Pandan B',
	BM2326: 'Kampong Pg. Setia Negara',
	BM2526: 'Kampong Pekan Lama',
	BN1111: 'Kampong Sumbiling Lama (Ayer)',
	BN1311: 'Kampong Bukit Salat',
	BN1511: 'Kampong Sungai Kedayan B',
	BN1711: 'Kampong Sungai Kedayan A',
	BN1911: 'Kampong Ujong Tanjong',
	BN2111: 'Kampong Kuala Peminyak',
	BN2311: 'Kampong Pemancha lama',
	BP1126: 'Kampong Peramu',
	BP1326: 'Kampong Pekilong Muara',
	BP1526: 'Kampong Setia Pahlawan Lama',
	BP1726: 'Kampong Bakut Siraja Muda A',
	BP1926: 'Kampong Bakut Siraja Muda B',
	BP2126: 'Kampong Lurong Sekuna',
	BP2326: 'Kampong Bakut Berumput',
	BR1126: 'Kampong Saba Laut',
	BR1326: 'Kampong Saba Darat A',
	BR1526: 'Kampong Saba Darat B',
	BR1726: 'Kampong Saba Ujong',
	BT1128: 'Pekan Muara',
	BT1328: 'Kampong Pelumpong',
	BT1528: 'Kampong Tanjong Batu',
	BT1728: 'Kampong Serasa',
	BT1928: 'Kampong Masjid Lama',
	BT2128: 'Kampong Sabun',
	BT2328: 'Kampong Kapok',
	BT2528: 'Kampong Salar',
	BT2728: 'Kampong Meragang',
	BT2928: 'Kampong Bukit Kabun',
	BT3128: 'Kampong Pengalayan',
	BT3328: 'Kampong Pulau Muara Besar',
	BT3528: 'Tanjong Pelumpong',
	BU1129: 'Kampong Tanah Jambu',
	BU1229: 'Kampong Sungai Buloh I',
	BU1329: 'Kampong Sungai Buloh II',
	BU1529: 'Kampong Batu Marang',
	BU1729: 'Kampong Panchor',
	BU1929: 'Kampong Mentiri',
	BU2129: 'Perpindahan Mentiri',
	BU2329: 'Kampong Paring',
	BU2529: 'Kampong Pengkalan Sibabau',
	KA1131: 'Kampong Pekan Belait',
	KA1331: 'Kampong Melayu Asli',
	KA1531: 'Kampong Mumong (Mumong Utara)',
	KA1731: 'Kampong Mumong Selatan',
	KA1931: 'Kampong Pandan',
	KA2131: 'Perumahan Negara Kampung Pandan',
	KA2331: 'Kampong China',
	KA2531: 'Kampong Perpindahan',
	KA2731: 'Kampong Rasau',
	KA2931: 'Kampong BSP',
	KA3131: 'Kampong Sungai Dulon',
	KA3331: 'Kampong Sungai Teraban',
	KA3531: 'Sungai Satu hingga Sungai Tujoh',
	KB1133: 'Kampong Pekan Seria',
	KB1333: 'Kampong Lorong Tiga Selatan Seria',
	KB1533: 'Perumahan Negara Lorong Tiga Seria',
	KB1733: 'Kampong Perakong',
	KB1933: 'Kampong Sungai Bera',
	KB2133: 'Kampong Jalan Badas',
	KB2333: 'Kampong Anduki',
	KB2533: 'Kampong Perpindahan Baru',
	KB2733: 'Kampong Masjid',
	KB2933: 'Kawasan Perumahan B.S.P Lorong 3-7',
	KB3133: 'Kawasan Perumahan B.S.P Lorong 8-13',
	KB3333: 'Kawasan Perumahan B.S.P Lorong 14-20',
	KB3533: 'Kawasan Shell Lorong 7 Utara hingga Sungai Bera',
	KB3733: 'Kampong Military brn_area',
	KB3933: 'Kampong Jabang (Setinggan)',
	KB4133: 'Kampoong Sekolah Mission',
	KB4333: 'Kawasan Jalan Bolkiah',
	KB4533: 'Panaga',
	KC1135: 'Kampong Sungai Liang/Gana',
	KC1335: 'Kampong Gana',
	KC1535: 'Kampong Andalau',
	KC1735: 'Kampong Keluyoh',
	KC1935: 'Kampong Perumpong',
	KC2135: 'Kampong Padang',
	KC2335: 'Kampong Liang Kecil',
	KC2535: 'Kampong Lilas',
	KC2735: 'Kampong Tunggulian',
	KC2935: 'Kampong Sungai Bakong Utara (Lumut Satu)',
	KC3135: 'Kampong Sungai Bakong Selatan (Lumut Satu)',
	KC3335: 'Kampong Tanah Tersusun (Lumut Satu)',
	KC3535: 'Kawasan LNG Lumut (Lumut Satu)',
	KC3735: 'Kampong Sungai Kuru (Lumut Satu)',
	KC3935: 'Kampong Sungai Kang (Lumut Satu)',
	KC4135: 'Kampong Agis-Agis (Lumut Satu)',
	KC4335: 'Kampong Lalit (Lumut Satu)',
	KC4535: 'Kampong Kayu Ara',
	// KC2735: 'Kampong Sungai Tali', // Duplicate key. Cannot find this on post.gov.bn
	KC4935: 'Kampong Sungai Taring',
	KC5135: 'Kampong Sungai Bakong',
	KD1132: 'Kampong Kuala Balai',
	KD1332: 'Kampong Mala?as',
	KD1532: 'Kampong Sungai Damit',
	KE1137: 'Kampong Bukit Puan',
	KE1337: 'Kampong Tapang Lupak',
	KE1537: 'Kampong Tenajor',
	KE1737: 'Kampong Ratan',
	KE1937: 'Kampong Gatas',
	KE2137: 'Kampong Kenapol',
	KE2337: 'Kampong Terunan',
	KE2537: 'Kampong Kesilin',
	KE2737: 'Kampong Labi Lama',
	KE2937: 'Kampong Mendaram Besar',
	KE3137: 'Kampong Mendaram Kecil',
	KE3337: 'Kampong Teraja',
	KE3537: 'Kampong Sungai Petani',
	KE3737: 'Kampong Malayan',
	KF1138: 'Kampong Sungai Mau',
	KF1338: 'Kampong Bisut',
	KF1538: 'Kampong Sungai Ubar',
	KF1738: 'Kampong Bukit Sawat',
	KF1938: 'Kampong Ubok-Ubok',
	KF2138: 'Kampong Bukit Kandol',
	KF2338: 'Kampong Labi Lakang',
	KF2538: 'Kampong Kagu',
	// KF2738: 'Kampong Bukit Serawong', // Cannot find on post.gov.bn
	KF2938: 'Kampong Singap',
	KF3138: 'Kampong Merangking Ulu',
	KF3338: 'Kampong Merangking Hilir',
	KF3538: 'Kampong Laong Arut',
	KF3738: 'Kampong Tarap',
	KF3938: 'Kampong Pangkalan Siong',
	KF4138: 'Kampong Bang Pukul',
	KF4338: 'Kampong Bang Tajuk',
	KF4538: 'Kampong Pulu Apil',
	// KF2738: 'Kampong Pangkalan Sungai Mau', // Cannot find on post.gov.bn
	KG1139: 'Kampong Apak-Apak',
	KG1339: 'Kampong Saud',
	KG1539: 'Kampong Buau',
	KG1739: 'Kampong Kukup',
	KG1939: 'Kampong Sukang',
	KG2139: 'Kampong Dungun',
	KG2339: 'Kampong Ambawang',
	KG2539: 'Kampong Biadong Tengah',
	KG2739: 'Kampong Biadong Ulu',
	KH1139: 'Kampong Tempinak',
	KH1339: 'Kampong Melilas',
	KH1539: 'Kampong Benggerang II',
	TA1141: 'Pekan Tutong',
	TA1341: 'Bukit Bendera',
	TA1541: 'Kampong Sungai Basong',
	TA1741: 'Kampong Suran',
	TA1941: 'Kampong Panchor Papan',
	TA2141: 'Kampong Panchor Dulit',
	TA2341: 'Kampong Sengkarai',
	TA2541: 'Kampong Serambangun',
	TA2741: 'Kampong Penanjong',
	TA2941: 'Kampong Tanah Burok',
	TA3141: 'Binturan',
	TA3341: 'Kampong Kuala Tutong',
	TA3541: 'Kampong Penabai',
	TB1141: 'Kampong Keriam',
	TB1341: 'Kampong Bukit Panggal',
	TB1541: 'Kampong Luagan Dudok',
	TB1741: 'Kampong Sinaut',
	TB1941: 'Kampong Meragang',
	TB2141: 'Kampong Bukit Nenas',
	TB2341: 'Kampong Santul',
	TB2541: 'Kampong Kerakas Payau',
	TB2741: 'Kampong Sungai Kelugos',
	TB2941: 'Kampong Kupang',
	TB3141: 'Kampong Maraburong Batu 16',
	TB3341: 'Kampong Ikas/Bandong',
	TC1145: 'Kampong Telisai',
	TC1345: 'Perumahan Negara Telisai',
	TC1545: 'Kampong Telamba',
	TC1745: 'Kampong Lalit',
	TC1945: 'Kampong Tanah Jambu',
	TC2145: 'Kampong Sungai Paku',
	TC2345: 'Kampong Danau',
	TC2545: 'Kampong Penapar (Telisai)',
	TC2745: 'Kampong Keramut',
	TC2945: 'Kampong Tumpuan Ugas',
	TC3145: 'Kampong Bukit Beruang',
	TC3345: 'Perumahan Negara Bukit Beruang',
	TD1141: 'Kampong Tanjong Maya/Meranti',
	TD1341: 'Kampong Sebakit',
	TD1541: 'Kampong Banggunggos',
	TD1741: 'Kampong Penapar (Tanjong Maya)',
	TD1941: 'Kampong Sungai Damit Pemadang',
	TD2141: 'Kampong Sungai Damit Ulu',
	TD2341: 'Kampong Bukit Udal',
	TD2541: 'Kampong Lubok Pulau',
	TE1143: 'Kampong Bakiau',
	TE1343: 'Kampong Pengkalan Mau',
	TE1543: 'Kampong Kiudang',
	TE1743: 'Kampong Mungkom',
	TE1943: 'Kampong Pad Nunok',
	TE2143: 'Kampong Birau',
	TE2343: 'Kampong Luagan Timbaran',
	TE2543: 'Kampong Batang Mitus',
	TE2743: 'Kampong Kebia',
	TF1127: 'Kampong Ukong',
	TF1327: 'Kampong Pengkalan Ran',
	TF1527: 'Kampong Pengkalan Dong',
	TF1727: 'Kampong Tong Kundai',
	TF1927: 'Kampong Nong Anggeh',
	TF2127: 'Kampong Sungai Damit Ulu',
	TF2327: 'Kampong Pitau Lambang',
	TF2527: 'Kampong Bang Pangan',
	// TF2727: 'Kampong Pak Meligai', // Canot find this on post.gov.bn
	TF2927: 'Kampong Pak Bidang',
	TF3127: 'Kampong Bukit',
	TF3327: 'Kampong Pengkalan Panchor',
	TF3527: 'Kampong Talat',
	TF3727: 'Kampong Melaboi',
	TF3927: 'Kampong Pengkalan Padang',
	TF4127: 'Kampong Bang Ligi',
	TF4327: 'Kampong Litad',
	TF4527: 'Kampong Bang Bingol',
	TF4747: 'Kampong Long Mayan',
	TG1143: 'Kampong Layong',
	TG1343: 'Kampong Kuala Abang',
	TG1543: 'Kampong Pengkalan Tangsi',
	TG1743: 'Kampong Belunu',
	TG1943: 'Kampong Pengkalan Dong',
	TG2143: 'Kampong Tanjong Dangar',
	TG2343: 'Kampong Lamunin',
	TG2543: 'Kampong Piasan',
	TG2743: 'Kampong Bukit Sulang',
	TG2943: 'Kampong Bentudoh',
	TG3143: 'Kampong Panchong',
	TG3343: 'Kampong Biong',
	TH1149: 'Kampong Rambai',
	TH1349: 'Kampong Merimbun',
	TH1549: 'Kampong Kuala Ungar',
	TH1749: 'Kampong Benutan',
	TH1949: 'Kampong Batang Piton',
	TH2149: 'Kampong Sengkowang',
	TH2349: 'Kampong Pelajau',
	TH2549: 'Kampong Kerancing',
	TH2749: 'Kampong Belaban',
	TH2949: 'Kampong Mapol',
	TH3149: 'Kampong Supon Besar',
	TH3349: 'Kampong Takalit',
	TH3549: 'Kampong Supon Kecil',
	TH3749: 'Kampong Lalipo',
	TH3949: 'Kampong Bedawan',
	TH4149: 'Kampong Belabau',
	PA1151: 'Pekan Bangar Lama',
	PA1351: 'Pekan Bangar Baru',
	PA1551: 'Perkemahan Bangar',
	PA1751: 'Kampong Menengah',
	PA1951: 'Kampong Sungai Sulok',
	PA2151: 'Kampong Sungai Tanit',
	PA2351: 'Kampong Sungai Tanam',
	PA2551: 'Kampong Balayang',
	PA2751: 'Kampong Semamang',
	PA2951: 'Kampong Buang Bulan',
	PA3151: 'Kampong Belingus',
	PA3351: 'Kampong Batang Tuau',
	PA3551: 'Kampong Seri Tanjong Belayang',
	PA3751: 'Kampong Puni',
	PA3951: 'Kampong Ujong Jalan',
	PB1151: 'Kampong Labu Estate',
	PB1351: 'Kampong Senukoh',
	PB1551: 'Kampong Piasau-Piasau',
	PB1751: 'Kampong Payau',
	PB1951: 'Kampong Ayam-Ayam',
	PC1151: 'Kampong Batu Apoi',
	PC1351: 'Kampong Sungai Radang',
	PC1551: 'Kampong Peliunan',
	PC1751: 'Kampong Sungai Bantaian',
	PC1951: 'Kampong Gadong Baru',
	PC2151: 'Kampong Luagan',
	PC2351: 'Kampong Negalang Iring',
	PC2551: 'Kampong Negalang Unat',
	PC2751: 'Kampong Lakiun',
	PC2951: 'Kampong Tanjong Bungar',
	PC3151: 'Kampong Lamaling',
	PC3351: 'Kampong Selapon',
	PC3551: 'Kampong Sekurop',
	PD1151: 'Kampong Amo A',
	PD1351: 'Kampong Amo B',
	PD1551: 'Kampong Amo C',
	PD1751: 'Kampong Sumbiling',
	PD1951: 'Kampong Batang Duri',
	PD2151: 'Kampong Sibut',
	PD2351: 'Kampong Parit',
	PD2551: 'Kampong Biang Menengah',
	PD2751: 'Kampong Selangan',
	PD2951: 'Kampong Sibulu',
	PD3151: 'Kampong Belaban',
	PD3351: 'Kawasan Bukit Belalong',
	PD3551: 'Kawasan Bukit Belutut',
	PE1151: 'Kampong Buda-Buda',
	PE1351: 'Kampong Belais',
	PE1551: 'Kampong Belais Kecil',
	PE1751: 'Kampong Paya Bagangan',
	PE1951: 'Kampong Bokok',
	PE2151: 'Kampong Meniup',
	PE2351: 'Kampong Bakarut',
	PE2551: 'Kampong Simbatang',
	PE2751: 'Kampong Rataie',
	PE2951: 'Kampong Perpindahan Rataie',
	PE3151: 'Kampong Rakyat Jati',
	PE3351: 'Kampong Kenua',
	PE3551: 'Kampong Lepong Baru',
	PE3751: 'Kampong Lepong Lama',
	PE3951: 'Kampong Semabat Bahagia',
	PE4151: 'Kampong Semabat',
	PE4351: 'Kampong Temada',
};


/**
 * Use Postcode to find a Brunei Area name.
 * 
 * @param {*} s 
 * @returns 
 */
const searchByPostcode = ( s ) => {

	let jsend = {
		status: 'fail',
		data: {
			'notFound': 'The postcode was not found in our database.'
		},
	};

	let matches = [];


	if ( POSTCODES_BN.hasOwnProperty( s ) ) {

		let obj = {
			area: POSTCODES_BN[s],
			postcode: s,
		};

		matches.push( obj );

		jsend.status = 'success';
		jsend.data.matches = matches;
		delete jsend.data.notFound;
	}

	return jsend;
}


/**
 * Use a Brunei area name to find postcode. Note that multiple Brunei area names may match so multiple postcodes could be returned.
 * 
 * @param {*} s 
 * @returns 
 */
const searchByAreaName = ( s ) => {

	// console.log( `Searching by Area Name: ${s}` );

	let jsend = {
		status: 'fail',
		data: {
			'notFound': 'Could not find any matching Brunei area in our database.'
		},
	};

	let names_to_search = Object.values( POSTCODES_BN ).filter( ( bn_name ) => {

		const re = new RegExp( `.*${s}.*`, 'i' );

		return re.test( bn_name );
	} );
	// console.log( `Brunei area names to search for: ${names_to_search}` );

	// Removes duplicate values.
	names_to_search = [...new Set( names_to_search )];
	// console.log( names_to_search );

	let matches = [];

	names_to_search.forEach( ( item, key ) => {

		let obj = {
			area: item,
			postcode: Object.keys( POSTCODES_BN )[Object.values( POSTCODES_BN ).indexOf( item )],
		};

		matches.push( obj );
	} );
	// console.log( matches );


	if ( matches.length ) {

		jsend.status = 'success';
		jsend.data.matches = matches;
		delete jsend.data.notFound;
	}
	// console.log( jsend );

	return jsend;
}


/**
 * Searches the POSTCODES_BN database.
 * 
 * @param {*} s 
 * @returns 
 */
const searchAll = ( s ) => {

	// console.log( `User entered ${s}` );

	const s_cleaned = function() {

		let s_cleaned = s
			.toUpperCase()
			// .replace( /kam/ig, '' )
			.replace( /kampong/ig, '' )
			.replace( /kampung/ig, '' )
			.replace( /kpg/ig, '' )
			.replace( /kpg\./ig, '' )
			.replace( /kg/ig, '' )
			.replace( /kg\./ig, '' )
			.replace( /mukim/ig, '' )
			.replace( /district/ig, '' )
			.trim()
		;

		return s_cleaned;
	}();
	// console.log( `Cleaned: ${s_cleaned}` );

	const pc_re = /[a-z]{2}[0-9]{4}/i;
	const is_pc_structure = pc_re.test( s_cleaned );

	if ( is_pc_structure ) {

		return searchByPostcode( s_cleaned );
	}

	if ( s_cleaned ) {

		return searchByAreaName( s_cleaned );
	}

	return `Could not search for ${s}`;
}

export default searchAll;