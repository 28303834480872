// Chakra UI
import { Box, Container, Center, Heading, Input } from "@chakra-ui/react"


const SearchBar = ( props ) => {

    // console.log( props );

    const updateSearchString = ( e ) => {

        const s = e.target.value;
        // console.log( s );

        // Only search if user has typed in at least 3 characters.
        if ( s.length < 3 ) {
    
            return;
        }

        props.setSearchString( s );
    };

    return (
        <Box py="4em" bg="#68cc55">
            <Container maxW="container.lg">
                <Heading mb="1em" as="h1">
                    <Center>Search Brunei Postcodes &amp; Kampongs</Center>
                </Heading>
                <Center>
                    <Input type="text" placeholder="Type a postcode or kampong name" onChange={updateSearchString} size="lg" variant="filled" />
                </Center>
            </Container>
        </Box>
    );
}

export default SearchBar;