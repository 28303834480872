// ChakraUI
import {
    Box,
    Center,
    Container,
    Table,
    Thead,
    Tbody,
    // Tfoot,
    Tr,
    Th,
    Td,
    // TableCaption,
  } from "@chakra-ui/react"


const SearchResults = ( props ) => {

    // console.log( props );
    
    let results_html = <p>Please enter a postcode or a kampong name in the field above.</p>;

    if ( props.searchResults !== '' ) {

        if ( props.searchResults.status === 'success' ) {

            const tbody_html = props.searchResults.data.matches.map( ( item ) => {

                return (
                    <Tr key={ item.postcode }>
                        <Td>
                            { item.area }
                        </Td>
                        <Td>
                            { item.postcode }
                        </Td>
                    </Tr>
                );
            } );

            results_html = <Table>
                <Thead>
                    <Tr>
                        <Th>Area Name</Th>
                        <Th>Postcode</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    { tbody_html }
                </Tbody>
            </Table>;
        } else if ( props.searchResults.status === 'fail' ) {

            results_html = <p>{props.searchResults.data.notFound}</p>;
        }
    }


    return (
        <Box py="3em" minH="24em">
            <Container maxW="container.lg">
                <Center>
                    { results_html }
                </Center>
            </Container>
        </Box>

    );
}

export default SearchResults;