//import logo from './logo.svg';
//import './App.css';
import searchAll from './components/postcodes-bn';
import React, { useEffect, useState } from 'react';
import SearchBar from './components/search-bar';
import SearchResults from './components/search-results';

// Chakra UI
import { Box, Container, Heading, Link, Text } from "@chakra-ui/react"

function App() {

    const [ searchString, setSearchString ] = useState( '' );
    const [ searchResults, setSearchResults ] = useState( '' );

    useEffect( () => {
        const searchResults = searchAll( searchString );
        setSearchResults( searchResults );
    }, [ searchString ] );


    return (
        <div className="App">
            <SearchBar setSearchString={setSearchString} />
            <SearchResults searchResults={searchResults} />
            
            <Box mt="3em" py="3em" background="#68cc55">
                <Container maxW="container.lg">
                    <Heading mb="0.5em">About this project</Heading>
                    
                    <Text mt="1em">
                        Around 2005 I stumbled upon a webpage containing (presumably) all the kampongs, mukims and districts in Brunei with their respective postcodes. I wrote a PHP script to scrape the data and save it into a MySQL database thinking that it might come in handy one day (my mum was a real estate agent at the time). I’d actually started creating a PHP app to search the database by name and/or postcode but like most things in life I left it aside and forgot about it.
                    </Text>

                    <Text mt="1em">
                        Fast-forward to 2021 when I started learning front-end development and was looking for a project to test things out. Fate intervened by sending me this tweet:
                    </Text>

                    <Box mt="1em" p="1em" borderRadius="lg" maxW="30em" bg="white">
                        <blockquote className="twitter-tweet"><p lang="en" dir="ltr">I&#39;m finishing up a work project that needs me to insert Brunei&#39;s every mukim and kampung, and <a href="https://twitter.com/mohamednazmi?ref_src=twsrc%5Etfw">@mohamednazmi</a> was impressed, saying that I know all mukims and kampungs now!<br/><br/>Him: Name a mukim in Tutong!<br/>Me: Kiudang!<br/>Him: Name a kampung in that mukim!<br/>Me: Kiudang!<br/>Him: 🙄<br/><br/>🤣🤣🤣</p>&mdash; Tina D (@my7thlife) <a href="https://twitter.com/my7thlife/status/1432209657389457411?ref_src=twsrc%5Etfw">August 30, 2021</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
                    </Box>

                    <Text mt="1em">
                        Suddenly I remembered the project and realized it would provide the perfect dataset. After a week of coding (followed by a month of nothing because I got busy), here it is ☺
                    </Text>

                    <Text mt="1em">
                        This is a project by Joshua Lim. <Link href="https://twitter.com/limshengming" color="white">Find me on Twitter</Link>.
                    </Text>

                    <Heading mt="1em" mb="0.5em">Caveats</Heading>

                    <Text mt="1em">
                        I do not claim that the data is correct. Remember I scraped it off a webpage over 15 years ago so the data might have changed, or it may have been incorrectly entered. I also had to remove a few kampongs because of duplicate postcodes.
                    </Text>
                </Container>
            </Box>
            
        </div>
    );
}

export default App;
